
import api from "../../../api/client-api";

export const GetAllAffiliates = async (): Promise<any> => {
  const affiliatesUrl = 'parameters/affiliate.webhook.url/';
  const response = await api.Get(affiliatesUrl);

  return response.map((affiliate: string) => ({ key: affiliate, id: affiliate }));
}

export const GetAffiliateWebhook = async (domain: string, key: string): Promise<any> => {
  const affiliateWebhookUrl = `parameters/affiliate.webhook.${domain}/${key}`;
  const response = await api.Get(affiliateWebhookUrl);

  return response;
}

export const PostAffiliateWebhook = async (domain: string, key: string, value?: string): Promise<any> => {
  const affiliateWebhookUrl = `parameters/affiliate.webhook.${domain}/${key}`;
  const response = await api.Post(affiliateWebhookUrl, {
    value: value ?? "",
    type: "string"
  });

  return response;
}

export const PutAffiliateWebhook = async (domain: string, key: string, value?: string): Promise<any> => {
  const affiliateWebhookUrl = `parameters/affiliate.webhook.${domain}/${key}`;
  const response = await api.Put(affiliateWebhookUrl, {
    value: value ?? "",
    type: "string"
  });

  return response;
}