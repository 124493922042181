import { FormForm } from '../../../biz/metadatas/application-forms';
import { FormColumns } from '../../../biz/metadatas/application-forms'
import { PutForm, GetIdForm, PostForm, GetAllForm } from '../../../services/api/dory'
import { GetParatiJSON } from '../../../services/api/parati'
import { getAllProducts } from '../../../api/applications';
import EntityPack from '../../components/lazyWay';

const FormPack = ({ ns }) => {

  const GetAllDomains = async () => {
    try {
      const domainToSave = ["products", "parati"]
      const response = await Promise.all([getAllProducts(), GetParatiJSON()])
      response.forEach((res, index) => {
        const which = domainToSave[index]
        let dm = res
        if (domainToSave[index] == "products") {
          dm = res.reduce(function (prev, curr, index) {
            if (index === 0) {
              return []
            }
            return [
              ...prev,
              ...["", "_A", "_B", "_campaign"].map(el => ({ code: curr.internalName + el, description: curr.description + el }))
            ]
          }, [{}])
        }
        if (res?.fields) {
          dm.fields = res.fields.map(field => ({ code: field, description: field }))
        }
        ns.setShared(`CACHE.domains.${which}`, dm)
      })

    } catch (err) {
      console.error(err)
    }
  }
  return EntityPack({
    ns,
    meta: FormForm,
    metaList: FormColumns,
    entity: "form",
    title: "Form",
    linkPath: "product",
    onCreate: GetAllDomains,
    handleUpdate: PutForm,
    actionCreate: PostForm,
    actionGet: GetIdForm,
    actionGetList: GetAllForm,
    actionUpdate: PutForm
  })
}

export default FormPack;