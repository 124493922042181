import { useEffect, useState } from 'react'
import { Jumbotron, Button, ButtonGroup } from 'react-bootstrap';
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import BlockUi from 'react-block-ui';
import { validateAppField } from '../../../biz/validate-application';
import FieldConnected from './field-connected';
import { FzCol, FzRow, FzCardGroup, FzSplitLine } from '../../fz/layout';
import { FzCard } from '../../fz/layout/FzCard';
import { useHistory } from 'react-router-dom';
import { FzButton } from '../../fz/form';
import { FzTable } from '../../fz/grid/table';
import SplitLine from '../../../components/split-line';
import { toastError } from '../lazyWay';
import { FzAccordion } from '../../fz/layout/FzAccordion';

var separator = "."
const toastInitialConfig = {
  show: false,
  headerMessageTost: 'Error',
  bodyMessageTost: 'Erro interno, tente novamente!',
  fzStyle: 'danger',
  delay: 5000,
  autoHide: true,
}

const addEndlineMeta = (basePath) => {
  const path = basePath + ".endline"

  return {
    path,
    span: 12,
    inputType: "endline",
  }
}

const replaceByIndex = (path, indexes = []) => {
  const pieces = path.split(".$.")
  if (pieces.length > 0) {
    let res = pieces.reduce((prev, curr, k) => {
      if (prev) {
        return `${prev}.${indexes[k - 1]}.${curr}`
      } else {
        return curr
      }
    }, "")
    return res.trimEnd(".")
  }
  return path
}

export const GenerateMetaStructure = ({ meta, data }, basePath = "", indexes = [], key) => {

  const baseObject = Object.keys(meta)
    .filter(el => el.startsWith("$"))
    .reduce((prev, curr) => {
      let res = cloneDeep(meta[curr])
      if (curr == "$namePath") {
        res = replaceByIndex(res, indexes)
      }
      return {
        ...prev,
        [curr]: res
      }
    }, {})

  return Object.keys(meta)
    .filter(el => !el.startsWith("$"))
    .reduce((prev, curr) => {
      const clonedMeta = cloneDeep(meta[curr])
      const baseDataKey = indexes.length > 0 ? replaceByIndex(curr, indexes) : curr
      if (clonedMeta.path) {
        clonedMeta.path = baseDataKey
      }
      if (baseDataKey.endsWith(".$")) {
        const dataKey = baseDataKey.replace(".$", "")
        const arraySize = get(data, dataKey, []).length
        const lista = cloneAndAddIndex(clonedMeta, arraySize, baseDataKey, data, indexes)
        return {
          ...prev,
          [baseDataKey]: lista
        }
      }

      if (clonedMeta?.excluded?.path) {
        clonedMeta.excluded.path = replaceByIndex(clonedMeta.excluded.path, indexes).replace(/\.([0-9]+)/g, '[$1]');
      }

      if (clonedMeta?.inputProps?.filteredFrom) {
        clonedMeta.inputProps.filteredFrom = clonedMeta.inputProps.filteredFrom.replace(".$", `.${key}`)
      }

      return {
        ...prev,
        [baseDataKey]: clonedMeta
      }
    }, baseObject)
}

const cloneAndAddIndex = (metas, n, basePath, data, indexes) => {

  const addButtonMeta = (bp) => {
    const dataPath = bp.replace(".$", "")
    const getDefaultValues = (meta) => {

      let ret = {}
      if (meta["$nameIsIndex"]) {
        const defaultKey = meta["$namePath"].split(".$.").pop()
        ret[defaultKey] = get(data, dataPath, []).length + 1
      }
      return ret
    }

    const path = bp + ".addButton"

    return {
      path,
      inputType: "button",
      label: "Adicionar",
      action: ({ fieldMeta, data, ns, key }) => {
        const current = get(data, dataPath) || []
        const newValue = [...current, cloneDeep({ ...getDefaultValues(metas) })]
        ns.saveChange(dataPath, newValue)
      }
    }
  }

  const removeButtonMeta = (bp, n) => {
    const dataPath = bp.replace(".$", "")
    const path = bp.replace("$", n) + ".removeButton"
    return {
      path,
      inputType: "button",
      label: "Remover",
      key: n,
      span: 12,
      action: ({ fieldMeta, data, ns, key }) => {
        let newData = cloneDeep(get(data, dataPath))
        if (newData && newData[n]) {
          newData.splice(n, 1)

          ns.saveChange(dataPath, newData)
        }
      }
    }
  }


  let res = Object.keys(metas)
    .filter(el => el.startsWith("$"))
    .reduce((prev, curr) => {
      return {
        ...prev,
        [curr]: cloneDeep(metas[curr])
      }
    }, {})
  for (let x = 0; x < n; x++) {
    const basePathIndex = basePath.replace(".$", `.${x}`)
    const result = GenerateMetaStructure({ meta: cloneDeep(metas), data }, basePathIndex, [...indexes, x], x)
    res[basePathIndex] = cloneDeep(result);

    if (!res["$readOnly"]) {
      res[basePathIndex]["removeButton"] = removeButtonMeta(basePath, x)
    }
  }

  if (!res["$readOnly"]) {

    if (!res["$maxLength"] || n < res["$maxLength"]) {
      res["addButtonMeta"] = addButtonMeta(basePath);
    }
  }

  return res
}

const handleSubmitAndCheckForm = ({ ns, metaPage, handleSubmit, setToast }) => {

  const values = ns.getChanged();
  const flatted = flatObject(cloneDeep(values))
  const val = {}

  Object.keys(metaPage).forEach(function (key) {
    val[key] = values[key]
  });

  const metasKeys = Object.keys(val).filter(el => !el.startsWith("$"))

  return Promise.all(metasKeys.map((k) => {
    const fieldMeta = get(metaPage, k)
    const path = fieldMeta["$path"] || fieldMeta["path"]
    return fieldMeta && typeof fieldMeta == "object" ? validateAppField({ value: get(values, path), data: values, ns, metaField: fieldMeta }) : Promise.resolve({ path, valid: false, reason: "metaPage not found for key of value", metaPage: fieldMeta })
  }))
    .then(results => {
      results = results.filter(it => !it.valid)
      results.forEach((it) => {
        if (!it.valid) {
          console.warn("Invalid field", it)
          ns.setInvalid(it.path)
        }
      })
      if (results.length > 0) {
        setToast(toastError("Erro de validação nos campos, por favor revise o formulário"))
        throw new Error(`error on validation: ${JSON.stringify(results)}`)
      }
      if (results.filter((it) => !it.valid).length === 0) {
        return values
      }
      return false
    })
    .then(handleSubmit)
    .then(ok => {
      ns.stopEditing()
      return ok
    })
    .catch(err => {
      console.error(err)
      return err
    })
}

let metaFlat = {}

const generateElement = ({ fieldMeta, data, ns, key }) => {

  if (fieldMeta?.excluded?.path) {
    const path = fieldMeta.excluded.path;
    const value = eval(`data.${path}`);

    if (fieldMeta.excluded.validator(value)) {
      fieldMeta.isMandatory = () => false;
      return null
    }

    // metaFlat[fieldMeta.path] = cloneDeep(fieldMeta);
  }

  switch (fieldMeta.inputType) {
    case "button":
      if (ns.isEditing()) {
        return <Button
          variant="primary"
          onClick={() => fieldMeta.action({ fieldMeta, data, ns, key })}
        >
          {fieldMeta.label}
        </Button>
      }
      return null

    case "endline":
      return <hr />

    default:
      return <FieldConnected
        key={key}
        meta={fieldMeta}
        ns={ns}
        data={data}
        index={key}
      />
  }
}

export const GenerateForm = ({ id, ns, data, title = "no-title", loading, metaPage, submit, goBack, setToast }) => {

  const generateForm = (meta, d, ns, index = 1, containerSpan = 12) => {
    if (!meta["$name"]) {
      return null
    }

    const elementName = get(d, meta["$namePath"]);

    return <>
      <FzCol span={containerSpan} key={index}>
        <h5>{elementName ?? ""}</h5>
        <FzRow >
          {Object.keys(meta)
            .filter(el => !el.startsWith("$"))
            .map((key, i) => {
              if (meta[key] && meta[key].path && (!meta["$nameIsIndex"] || meta[key].path != meta["$namePath"])) {
                return <FzCol key={`col-${i}`} span={meta[key]?.span ?? 6}>
                  {generateElement({ fieldMeta: meta[key], data: d, key: i, ns })}
                </FzCol>
              } else if (typeof meta[key] == "string") {
                return null
              } else {
                return <>
                  {handleList(meta[key])}
                </>
              }
            })}
        </FzRow>
      </FzCol>
    </>
  }

  const dig = (obj, path) => {
    if (!obj || typeof obj !== 'object') return undefined;

    const keys = path.split('.');

    return keys.reduce((current, key) => {
      if (current === undefined || current === null) return undefined;

      const isIndex = !isNaN(Number(key));
      const parsedKey = isIndex ? Number(key) : key;

      return current[parsedKey];
    }, obj);
  };

  const handleList = (meta) => {

    const innerMeta = cloneDeep(Object.keys(meta)
      .filter(el => el.startsWith("$"))
      .map(el => ({ [el]: meta[el] }))
      .reduce((prev, curr) => ({ ...prev, ...curr }), {}))
    metaFlat = { ...metaFlat, ...innerMeta }

    return <>
      <div style={{ marginTop: "30px", width: "100%" }}>
        <FzCol span={12}>
          <h5>{meta["$name"]}</h5>
          {
            Object.keys(meta).filter(el => !el.startsWith("$"))
              .map((k, i) => {
                if (meta[k]?.path) {
                  if (meta[k].inputType == "button" && !ns.isEditing()) {
                    return null
                  } else {
                    return <>
                      {generateElement({ fieldMeta: meta[k], data, key: i + (i * 10), ns })}
                    </>
                  }
                }

                let name;
                const dataGroup = dig(data, k)

                if (dataGroup) {
                  name = dataGroup[meta["$namePath"]]
                }

                return <>
                  <FzAccordion key={i}>
                    <FzAccordion.Toggle FzElementType={'h2'} eventKey={k}>
                      <FzCard.Header>
                        <FzCard.Text>
                          {name ?? "Novo"}
                        </FzCard.Text>
                      </FzCard.Header>
                    </FzAccordion.Toggle>
                    <FzAccordion.Collapse eventKey={k}>
                      <FzCard.Body>
                        {generateForm(meta[k], data, ns, i * 2 + (i * 10), 12)}
                      </FzCard.Body>
                    </FzAccordion.Collapse>
                  </FzAccordion>
                </>
              })
          }
        </FzCol >
      </div>
    </>
  }

  const form = <FzRow>{generateForm(metaPage, data, ns)}</FzRow>

  const buttonGroupIsEditing = <ButtonGroup>
    <Button key={"isEditing-gravar"} variant="primary" onClick={() => handleSubmitAndCheckForm({ ns, metaPage: metaFlat, handleSubmit: submit, setToast })} >
      Gravar
    </Button>
    <Button key={"isEditing-cancelar"} variant="default" onClick={id == "new" ? goBack : () => ns.stopEditing()} >
      Cancelar
    </Button>
  </ButtonGroup>

  const buttonGroupIsNotEditing = <ButtonGroup>
    <Button key={"isEditing-editar"} variant="primary" onClick={() => ns.startEditing(data)} >
      Editar
    </Button>
    <Button key={"isEditing-voltar"} variant="default" onClick={goBack} >
      Voltar
    </Button>
  </ButtonGroup>
  const buttons = (ns.isEditing() ? buttonGroupIsEditing : buttonGroupIsNotEditing)

  return <BlockUi tag="div" blocking={loading} message="Processando informações...">
    <Jumbotron>
      {buttons}
      <FzCardGroup bsPrefix={`${title.toLowerCase()}-form`}>
        <FzCard>
          <FzCard.Header>
            <FzSplitLine>
              <FzSplitLine.Left>
                <FzCard.Title>{title}</FzCard.Title>
              </FzSplitLine.Left>
            </FzSplitLine>
          </FzCard.Header>
          <FzCard.Body>
            {form}
          </FzCard.Body>
        </FzCard>
      </FzCardGroup>
    </Jumbotron>
  </BlockUi>
}

export function flatObject(obj, prefix = "") {
  if (prefix.length > 0) {
    prefix = prefix + separator
  }
  let res = {
    ...Object.keys(obj).reduce((acc, key) => {
      var val = obj[key]
      if (Array.isArray(val)) {
        val = flatArray(val, prefix + key)
      } else if (typeof val == "object" && Object.prototype.toString.call(val) == "[object BSON]") {
        val = flatObject(val, prefix + key)
      } else {
        val = {
          [prefix + key]: val
        }
      }
      delete obj[prefix + key]
      return {
        ...acc,
        ...val
      }
    }, {})
  }
  return res
}

export function flatArray(arr, key) {
  return {
    ...arr.reduce((prev, curr, index) => {
      var val = {}
      if (Array.isArray(curr)) {
        val = flatArray(curr, `${key}${separator}${index}`)
      } else if (typeof curr == "object") {
        val = flatObject(curr, `${key}${separator}${index}`)
      } else {
        val = {
          [`${key}${separator}${index}`]: val
        }
      }
      return {
        ...prev,
        ...val
      }
    }, {})
  }
}

function handleUpdateDefault(data) {
  return data
}

export const Edit = ({ user, ns, id, entity, meta, title, actionGet, actionCreate, actionUpdate, onCreate, updated = new Date(), handleUpdate = handleUpdateDefault, setToast }) => {
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({})

  if (!user || meta["$group"] && (user.group || "").indexOf(meta["$group"]) == -1) {
    history.push(`/`);
  }

  useEffect(() => {
    setLoading(true)
    try {
      if (onCreate) {
        onCreate()
      }
      buildform()
    } catch (error) {
      setLoading(false)
      setToast(toastError("Error building form: " + error));
      console.error(error)
    }
  }, [])

  const getBodyBasedOnMeta = (b, pageMeta) => {
    return Object.keys(b)
      .filter(el => pageMeta[el] || pageMeta[el + ".$"])
      .reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: b[curr]
        }
      }, {})

  }

  const handleSubmit = async (execute) => {
    if (execute) {
      try {
        setLoading(true)
        let payload = getBodyBasedOnMeta(ns.getChanged(), meta)
        let res = payload
        if (!id || id === "new") {
          res = await actionCreate(payload);
          if (res.body) {
            history.push(`/${entity}/${res.body.id}`);
          }
        } else {
          res = await actionUpdate(id, payload);
        }
        return res

      } catch (error) {
        console.error("error on handleSubmit", error)
        setToast(toastError(`Erro ao atualizar o ${title}: ${error}`));
        console.error(error)
      } finally {
        setLoading(false)
        goToList()
      }
    } else {
      console.log("NOT EXECUTED")
    }
    return false
  }


  const goToList = () => {
    history.push(`/${entity}`);
  }

  const buildform = async () => {
    try {
      let res = {}
      if (id && id != "new") {
        res = await actionGet(id)
        // data = res
      }
      setData(res)
      ns.set(entity, res);
      if (!id || id == "new") {
        ns.startEditing(data)
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
      setToast(toastError(`Error building form: ${error}`));
    }
  }

  return (
    <FormGenerator
      id={id}
      title={title}
      metaForm={meta}
      ns={ns}
      handleSubmit={handleSubmit}
      goBack={goToList}
      setToast={setToast}
      loading={loading}
      data={data}

    />
  )
}

export const List = ({ ns, entity, title, actionGet, metas, linkPath = "id" }) => {
  let history = useHistory();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const response = await actionGet()
      setData(response)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const goToElement = (_, row) => {
    console.log(row, linkPath)
    return <FzButton
      customStyle={{
        borderColor: '#5923DD',
        width: '100%',
        color: '#fff',
        background: '#5923DD'
      }}
      size="small"
      fzStyle=""
      onClick={() => history.push(`/${entity}/${row[linkPath]}`)}
    >
      Ver
    </FzButton>
  }

  return (
    <BlockUi tag="div" blocking={loading} message="Processando informações...">
      <div style={{ display: "flex", padding: "20px", }}>
        <FzCard>
          <FzCard.Header>
            <FzCard.Title>
              <SplitLine>
                <SplitLine.Left>
                  {title}'s
                </SplitLine.Left>
                <SplitLine.Right>
                  <FzButton
                    size="small"
                    fzStyle="regular"
                    onClick={() => history.push(`/${entity}/new`)}
                  >
                    Adicionar {title}
                  </FzButton>
                </SplitLine.Right>
              </SplitLine>
            </FzCard.Title>
          </FzCard.Header>
          <FzCard.Body>
            <FzTable
              data={data}
              metas={metas}
              ns={ns}
              exportDownload={false}
              clipboard={false}
              customColumnFormatters={{
                linkPath: (cell, row) => goToElement(cell, row)
              }}
            />
          </FzCard.Body>
        </FzCard>
      </div>
    </BlockUi>
  )
}

const FormGenerator = ({
  id,
  title,
  metaForm,
  ns,
  handleSubmit,
  goBack,
  loading,
  setToast,
  data,
}) => {
  data = ns.isEditing() ? ns.getChanged() : data
  const metaPage = GenerateMetaStructure({ meta: metaForm, data })
  return GenerateForm({
    id,
    data,
    loading,
    metaPage,
    ns,
    title,
    submit: handleSubmit,
    goBack: goBack,
    setToast
  })
}

export default FormGenerator;
