import { GetAllAffiliates } from '../../../../services/api/parameters'
import { AffiliateColumns } from '../../../../biz/metadatas/affiliate'
import { List } from '../../../components/form/form-generator'

const Affiliate = ({ ns }) => {
  return (
      <List
        metas={AffiliateColumns}
        actionGet={GetAllAffiliates}
        entity='affiliates'
        title='Afiliado'
        ns={ns}
      />
  )
}

export default Affiliate
